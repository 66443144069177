@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$link-color: #1677fa;

a {
  @apply font-bold;
  color: $link-color;

  &:hover {
    color: darken($link-color, 20);
  }
}

p {
  @apply mt-4;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-bold mt-4 mb-2;
}

ul {
  @apply pl-6;

  li {
    @apply list-disc;
  }
}

section {
  @apply p-4 rounded-xl;
  background: rgba(255, 255, 255, 0.75);
}
